import React, { useEffect, useState } from 'react'

// Components
import ActivityStudentList, { Item } from '../list/ActivityStudentList'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import { Box } from '@mui/system'
import Button from '../../button/Button'
import { Checkbox, CircularProgress, Grid } from '@mui/material'
import CollapseTable from '../../table/CollapseTable'
import { Field } from 'react-final-form'
import Input from '../../input/Input'
import FiltersSelect from '../../select/FiltersSelect'
import Label from '../../label/Label'
import NoItemsCard from '../../card/NoItemsCard'
import SelectField from '../../select/SelectField'
import SimpleDatePickerField from '../../input/SimpleDatePickerField'

// Redux
import { classroomFetchRequest } from '../../../store/questions/actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Utils
import { styled } from '@mui/material/styles'
import { checkCurrentRole } from '../../../utils/functions'
import { required } from '../../../utils/formUtils'

// Icons
import { ReactComponent as PeopleIcon } from '../../../assets/components/activity/peopleIcon.svg'
import { ReactComponent as NoOptionsSVG } from '../../../assets/no-classroom.svg'

const DivBtns = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: '3rem',
  '& > .MuiButton-containedSecondary': {
    color: '#FFF'
  }
})

const PeopleIconStyle = styled(PeopleIcon)({
  marginRight: 16,
  height: 20,
  width: 20,
  '& path': {
    fill: '#9F9F9F'
  }
})

const CollapseTitleStyle = styled('span')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: 8,
  color: '#FFF',
  fontSize: 14,
  padding: '5px 18px'
}))

const FieldsContainer = styled('div')({
  borderRadius: '16px',
  padding: '28px 0',
  marginTop: 16,
  '& .react-datepicker__input-container': {
    '& .MuiInputBase-root': {
      height: 40,
      marginTop: 4,
      width: '100%'
    }
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFF'
  }
})

const ActivitiesDestinationForm = (props: any) => {
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    previous,
    selectedRole,
    user,
    questions,
    toggleCheckboxClassroom,
    toggleCheckboxAllStudents,
    handleDraftSubmit,
    selectedClassrooms,
    unitId,
    values,
    isSubmitDraft,
    isSubmitForm,
    isClassroomSelected,
    invalid
  } = props

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const { isSavingOrUpdating } = useAppSelector(state => state.activities)
  const isTeacher = user?.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')

  useEffect(() => {
    dispatch(classroomFetchRequest({ unitIds: [unitId] }))
  }, [unitId])

  useEffect(() => {
    if (values && values?.start_date) setStartDate(values.start_date)
    if (values && values?.end_date) setEndDate(values.end_date)
  }, [])

  const handleStartChanges = (startValue: any) => {
    setStartDate(startValue)
  }

  const handleEndChanges = (endValue: any) => {
    setEndDate(endValue)
  }


  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(endDate)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(startDate)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  const verifyStudentChecked = (student: any, classroom: any) => !!selectedClassrooms.byId[student.id]?.school_classroom_ids &&
    selectedClassrooms.byId[student.id].school_classroom_ids.find(
      (id: number) => id === classroom.id
    )

  return (
    <form onSubmit={handleSubmit}>
      <FieldsContainer>
        <Grid container spacing={2}>
          <Grid item sm={4} lg={2}>
            <Field
              name='start_date'
              component={SimpleDatePickerField}
              placeholder='00/00/00 00:00'
              label='Data de início'
              autoComplete='off'
              validate={required}
              required
              showTimeSelect
              locale='pt-BR'
              timeIntervals={1}
              timeCaption='Hora'
              dateFormat='dd/MM/yyyy HH:mm'
              startDate={startDate}
              endDate={endDate}
              selectsStart
              changeDate={handleStartChanges}
              maxDate={endDate}
              filterTime={endDate && filterRangeStartDate}
              readOnly
            />
          </Grid>
          <Grid item sm={4} lg={2}>
            <Field
              name='end_date'
              component={SimpleDatePickerField}
              label='Data de fim'
              placeholder='00/00/00 00:00'
              autoComplete='off'
              validate={required}
              required
              showTimeSelect
              locale='pt-BR'
              timeIntervals={1}
              timeCaption='Hora'
              dateFormat='dd/MM/yyyy HH:mm'
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              selectsEnd
              changeDate={handleEndChanges}
              filterTime={filterRangeEndDate}
              readOnly
            />
          </Grid>
          <Grid item sm={4} lg={8}>
            <Field
              component={FiltersSelect}
              placeholder='Selecione...'
              label='Tipo de correções'
              name='content_module_type'
              validate={required}
              required
              hasBorder
              options={values && values?.activity_type?.value === 'exam'
                ? [{ id: 1, name: 'O gabarito aparece somente na data de término da prova', value: 'exam' }]
                : values?.activity_type?.value === 'commom'
                  ? [{ id: 2, name: 'O gabarito aparece durante a execução da prova', value: 'regular' }]
                  : []
              }
            />
          </Grid>
          <Grid item sm={4} lg={2}>
            <Field
              name='duration'
              component={Input}
              placeholder='Tempo de prova (minutos)'
              min={1}
              max={500}
              type='number'
              label='Tempo de prova'
            />
          </Grid>
          <Grid item sm={4} lg={5}>
            <Field
              name='subjects'
              component={AsyncSelectInput}
              placeholder='Selecione...'
              label='Disciplina'
              searchParam='name'
              request={{
                path: '/subjects',
                params: {
                  basic: true,
                  teacher_user_id: isTeacher ? user?.id : null
                }
              }}
            />
          </Grid>
          <Grid item sm={4} lg={5}>
            <Field
              component={SelectField}
              placeholder='Selecione...'
              label='Aplicação da prova'
              name='exam_type'
              hasBorder
              defaultFieldValue={{ name: 'Virtual', value: 'virtual' }}
              options={[
                // { name: 'Presencial', value: 'presential' },
                { name: 'Virtual', value: 'virtual' }
              ]}
            />
          </Grid>
        </Grid>
      </FieldsContainer>
      <Label><PeopleIconStyle />Selecionar turmas</Label>
      {questions.classrooms.isFetching && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
          <CircularProgress thickness={2} />
        </Box>
      )}
      {questions.classrooms.items.length > 0 && questions.classrooms.items.map((item: Item) => {
        function divideStudents(items: any): string[][] {
          const { students } = items
          const dividedArrays: string[][] = [[], [], [], []]
          const chunkSize = Math.ceil(students.length / 4)
          for (let i = 0; i < students.length; i++) {
            const chunkIndex = Math.floor(i / chunkSize)
            dividedArrays[chunkIndex].push(students[i])
          }
          return dividedArrays
        }
        const dividedStudentsList = divideStudents(item)
        return (
          <CollapseTable
            key={`collapse-${item.id}`}
            primaryText={
              <CollapseTitleStyle>
                {item.full_name}
              </CollapseTitleStyle>
            }
            secondaryText={
              <Box sx={{ display: 'flex', alignItems: 'center', width: '370px', justifyContent: 'space-between' }}>
                <Box sx={{ fontSize: 14, fontWeight: 500 }}>
                  <Checkbox
                    onChange={e => {
                      return toggleCheckboxAllStudents(e, item)
                    }}
                    checked={item?.students.length > 0 && item?.students.every(student => {
                      return verifyStudentChecked(student, item)
                    }
                    )}
                  />
                  Selecionar toda turma
                </Box>
                <Box component='span' sx={{ display: 'flex', fontSize: 14, gap: 1, alignItems: 'center', justifyContent: 'space-between', ml: 4 }}>
                  <PeopleIcon />
                  {item.students.length} estudantes
                </Box>
              </Box>
            }
            details={
              <div>
                <ActivityStudentList
                  dividedStudentsList={dividedStudentsList}
                  toggleCheckboxClassroom={toggleCheckboxClassroom}
                  verifyStudentChecked={verifyStudentChecked}
                  item={item}
                />
              </div>
            }
          />
        )
      })}
      {!questions.classrooms.isFetching && questions.classrooms.items.length === 0 && (
        <NoItemsCard>
          <NoOptionsSVG />
          <h3>Você ainda não possui turmas</h3>
          <p>Para visualizar suas <span>turmas</span> entre em contato com a coordenação de sua escola</p>
        </NoItemsCard>
      )}
      <DivBtns>
        <Button variant='outlined' disabled={isSavingOrUpdating} onClick={previous}>Voltar</Button>
        <Button
          disabled={invalid || isSavingOrUpdating || !isClassroomSelected}
          onClick={() => handleDraftSubmit(values)}
          startIcon={
            isSubmitDraft && isSavingOrUpdating
              ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
              : undefined
          }
        >
          Salvar rascunho
        </Button>
        <Button
          color='secondary'
          type='submit'
          disabled={invalid || isSavingOrUpdating || !isClassroomSelected}
          startIcon={
            isSubmitForm && isSavingOrUpdating
              ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
              : undefined
          }
        >
          Finalizar
        </Button>
      </DivBtns>
    </form>
  )
}

export default ActivitiesDestinationForm
