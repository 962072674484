import React from 'react'

// Assets
import { ReactComponent as SchoolIcon } from '../../../assets/components/Books/school-icon.svg'
import ChartIcon from '../../../assets/components/Books/chart-light-icon.svg'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import PeopleIcon from '../../../assets/components/Books/people-light-icon.svg'
import { ReactComponent as ChartCircled } from '../../../assets/components/Books/chart-circled.svg'
import { ReactComponent as EyeIcon } from '../../../assets/components/Books/eye-icon.svg'

// Components
import {
  Box,
  Collapse,
  LinearProgress,
  Typography
} from '@mui/material'
import {
  CollapseItemContainer,
  CollapseItemTitle,
  LinearProgressBox,
  PerformanceTitle
} from '../../../containers/BooksReportClassroomPerformanceContainer'
import ClassroomStudentCardReport from './ClassroomStudentCardReport'
import Button from '../../button/Button'

// Styles
import { useTheme, styled } from '@mui/material/styles'

export const PerformanceContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '32px'
}))
export const PerformanceTitleContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '56px',
  paddingLeft: '16px',
  marginBottom: '8px'
}))

interface ISingleClassroomCardReportProps {
  resolutionSectionRef: any
  handleSeeClassroom: any
  toggleOpenClassroom: boolean
}

const SingleClassroomCardReport: React.FC<ISingleClassroomCardReportProps> = ({
  resolutionSectionRef,
  handleSeeClassroom,
  toggleOpenClassroom
}) => {
  const theme = useTheme()
  return (
    <Box
      ref={resolutionSectionRef}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '11px 15px',
            marginTop: '10px',
            background: '#F9F9F9',
            borderRadius: '8px',
            width: '100%',
            '& > p': {
              fontSize: '14px',
              fontWeight: 600
            }
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CollapseItemContainer
              sx={{
                justifyContent: 'space-between',
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={handleSeeClassroom}
            >
              <Typography
                sx={{

                  background: theme.palette.primary.light,
                  borderRadius: '8px',
                  color: '#fff',
                  fontWeight: '400 !important',
                  padding: '6px 18px',

                }}
              >
                1º E.F. I - Turma A 1º EF I
              </Typography>
              <CollapseItemContainer
                sx={{
                  '& > svg > path': {
                    stroke: '#d9d9d9'
                  },

                }}
              >
                <SchoolIcon />
                <CollapseItemTitle>Nome da escola</CollapseItemTitle>
              </CollapseItemContainer>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                }}
              >
                <img src={ChartIcon} />
                <CollapseItemTitle>
                  Desempenho:
                </CollapseItemTitle>
                <CollapseItemContainer
                  sx={{
                    width: '100%',
                    '.MuiLinearProgress-bar': {
                      backgroundColor: '#04AEC9'
                    }
                  }}
                >
                  <LinearProgressBox>
                    <LinearProgress
                      sx={{
                        backgroundColor: theme.palette.text.secondary,
                        '.MuiLinearProgress-bar': { backgroundColor: '#04AEC9' }
                      }}
                      variant='determinate'
                      value={Number(60)}
                    />
                  </LinearProgressBox>
                  <Typography fontWeight={500}>60%</Typography>
                </CollapseItemContainer>
              </Box>
              <CollapseItemContainer>
                <ChartCircled />
                <CollapseItemTitle>Progresso:</CollapseItemTitle>
                <LinearProgressBox>
                  <LinearProgress
                    sx={{
                      backgroundColor: theme.palette.text.secondary,
                      '.MuiLinearProgress-bar': { backgroundColor: '#04AEC9' }
                    }}
                    variant='determinate'
                    value={Number(30)}
                  />
                </LinearProgressBox>
                <Typography fontWeight={500}>30%</Typography>

              </CollapseItemContainer>
              <CollapseItemContainer>
                <img src={PeopleIcon} />
                <Typography fontWeight={500}>Acessos</Typography>
                <Typography fontWeight={500}>000</Typography>
              </CollapseItemContainer>
              {!toggleOpenClassroom ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowUpRoundedIcon />}
            </CollapseItemContainer>
          </Box>
          <Collapse in={toggleOpenClassroom} timeout={500} >
            <Box
              mt='32px'
              ref={resolutionSectionRef}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <PerformanceTitleContainer>
                  <PerformanceTitle>Alto desempenho</PerformanceTitle>
                  <PerformanceTitle>de x% a x%</PerformanceTitle>
                </PerformanceTitleContainer>
                <PerformanceContainer>
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                </PerformanceContainer>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <PerformanceTitleContainer>
                  <PerformanceTitle>Médio desempenho</PerformanceTitle>
                  <PerformanceTitle>de x% a x%</PerformanceTitle>
                </PerformanceTitleContainer>
                <PerformanceContainer>
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                </PerformanceContainer>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '56px',
                    pl: '16px',
                    mb: '8px'
                  }}
                >
                  <PerformanceTitle>Baixo desempenho</PerformanceTitle>
                  <PerformanceTitle>de x% a x%</PerformanceTitle>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px'
                  }}
                >
                  <ClassroomStudentCardReport />
                  <ClassroomStudentCardReport />
                </Box>
              </Box>
              <Box
                display='flex'
                justifyContent='flex-end'
                marginBlock='32px'
              >
                <Button
                  type='button'
                  sx={{
                    background: '#fff',
                    padding: '24px',
                    color: '#B7B2C8',
                    borderColor: '#B7B2C8',
                    fontWeight: 500,
                  }}
                  variant='outlined'
                  startIcon={
                    <EyeIcon />
                  }
                >
                  Visualizar turma
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>

    </Box>
  )
}

export default SingleClassroomCardReport
