import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { styled } from '@mui/material/styles'
import { COLORS } from '../../utils/constants'

const TooltipContainer = styled(Box)(() => ({
  background: '#ffffff',
  border: '1px solid #d9d9d9',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '20px 8px'
}))

const TooltipContent = styled(Box)(({ theme }) => ({
  '&:not(&:first-of-type)': {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    paddingTop: '20px'
  },

  '.tooltip_title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '8px'
  },

  '.tooltip_content': {
    '&-label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    },

    '&-value': {
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      marginLeft: '4px',
      padding: '4px 6px',
    },

    '& .modules': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '32px',
      color: theme.palette.secondary.main
    },

    '& .performance': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '32px',
      color: theme.palette.primary.light
    }
  }
}))

const LegendContainer = styled('ul')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  listStyleType: 'none'
}))

const LegendItem = styled('li')<any>(({ background, isHiden }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  gap: '4px',
  '& .circle': {
    background: isHiden ? '#ccc' : background,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '12px',
    width: '12px',
  },

  '& .label': {
    fontSize: '12px',
    fontWeight: 500,
    textDecoration: isHiden ? 'line-through' : 'none',
  }
}))

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        {payload.map((item: any, index: number) => {
          return (
            <TooltipContent key={`${item?.color}-${index}`}>
              <Typography className='tooltip_title'>{item.dataKey}</Typography>
              <Box className='tooltip_content'>
                <Typography
                  component='span'
                  className='tooltip_content-label'
                >
                  Módulos:
                </Typography>
                <Typography
                  component='span'
                  className='tooltip_content-value modules'
                >
                  {item.payload[`${item.dataKey} - modules`]}
                </Typography>
              </Box>

              <Box className='tooltip_content'>
                <Typography
                  component='span'
                  className='tooltip_content-label'
                >
                  Performance:
                </Typography>
                <Typography
                  component='span'
                  className='tooltip_content-value performance'
                >
                  {item.payload[item.dataKey]}%
                </Typography>
              </Box>
            </TooltipContent>
          )
        })}
      </TooltipContainer>
    )
  }
  return null
}

const CustomLegend = ({ payload, onClick, lineProps }: any) => {
  return (
    <LegendContainer>
      {payload.map((item: any, index: number) => {
        return (
          <LegendItem
            key={index}
            background={item?.color}
            isHiden={lineProps?.[item.dataKey]}
            onClick={() => onClick(item)}
          >
            <span className='circle' />
            <span className='label'>{item.value}</span>
          </LegendItem>)
      })}
    </LegendContainer>
  )
}


const LineChartComponent = ({ label, chartData }: any) => {
  const [lineProps, setLineProps] = useState(
    label.reduce((acc: any, item: any) => {
      acc[item.school_classroom.name] = false
      return acc
    }, {})
  )

  const handleClickLegend = (event: any) => {
    setLineProps({
      ...lineProps,
      [event.dataKey]: !lineProps[event.dataKey]
    })
  }

  return (
    <Box sx={{ height: '300px', width: '100%' }}>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart data={chartData} height={300}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            label={{
              value: 'Capítulos',
              styles: { textAnchor: 'middle' },
              position: 'bottom',
              offset: -10
            }}
            height={45}
          />
          <YAxis
            tickCount={6}
            domain={[0, 100]}
            label={{
              value: 'Performance (%)',
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: -11
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign='top'
            content={
              <CustomLegend
                onClick={handleClickLegend}
                lineProps={lineProps}
              />
            }
          />
          {label?.map((item: any, index: number) => {
            return (
              <Line
                key={item.school_classroom.id}
                type='monotone'
                dataKey={item.school_classroom.name}
                stroke={COLORS[index]}
                hide={lineProps?.[item?.school_classroom?.name] === true}
                strokeWidth={2}
              />
            )
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default LineChartComponent
