import http from '../../utils/http'

const booksEndpoint = '/books'
const chapterEndpoint = '/book_chapters'
const teachersClassrooms = '/teachers/classrooms'
const teachersEndpoint = '/user_teachers'
const autorsEndpoint = '/authors'
const coautorsEndpoint = '/co_authors'
const teachersAuthors = '/teachers/authors'

export const getBooks = (params: any) => {
  return http.get(booksEndpoint, params)
}

export const getCovers = () => {
  return http.get(`${booksEndpoint}/default_covers`)
}

export const editBookCoauthor = (params: any) => {
  return http.put(`${booksEndpoint}/${params.bookId}`, params)
}

export const createNewBook = (params: any) => {
  return http.post(booksEndpoint,
    params,
    { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const updateNewBook = (params: any) => {
  return http.put(`${booksEndpoint}/${params.id}`,
    params.data,
    { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const addCoverBookImage = (params: any) => {
  return http.patch(`${booksEndpoint}/${params.id}`,
    params.fd,
    { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const fetchBook = (params: any) => {
  return http.get(`${booksEndpoint}/${params?.id}`)
}

export const createNewChapter = (params: any) => {
  return http.post(chapterEndpoint, params)
}

export const fetchNewChapter = (params: any) => {
  return http.get(`${booksEndpoint}/${params?.id}/chapters`)
}

export const editChapter = ({ params, id }: { params: any, id: number }) => {
  return http.put(`${chapterEndpoint}/${id}`, params)
}

export const deleteChapterById = (id: any) => {
  return http.delete(`${chapterEndpoint}/${id}`)
}

export const changeChapterOrder = ({ params, id }: { params: any, id: number }) => {
  return http.patch(`${chapterEndpoint}/${id}`, params)
}

export const deleteteBookById = (id: any) => {
  return http.delete(`${booksEndpoint}/${id}`)
}

export const getClassrooms = (params: any) => {
  return http.get(teachersClassrooms, { params })
}

export const addClassroomsOnBook = (params: any) => {
  return http.post(`${booksEndpoint}/${params.id}/for_classroom`, {
    school_classroom_ids: params.school_classroom_ids,
    modules: params.modules
  })
}

export const changeClassroomsOnBook = (params: any) => {
  return http.put(`${booksEndpoint}/${params.id}/for_classroom`, {
    school_classroom_ids: params.school_classroom_ids,
    modules: params.modules
  })
}

export const getBookPublished = (params: any) => {
  return http.get(`${booksEndpoint}/${params.id}/get_for_classroom`)
}

export const cloneBook = (params: any) => {
  return http.post(booksEndpoint, params)
}

export const cloneChapters = (params: any) => {
  return http.post(chapterEndpoint, params)
}

export const getTeacherWithSubjects = ({schoolUnitId}: any) => {
  return http.get(`${teachersEndpoint}/subjects?school_unit_id=${schoolUnitId}?per=999`)
}

export const publishBook = ({ params, id }: { params: any, id: number }) => {
  return http.post(`${chapterEndpoint}/${id}/update_relations`, params)
}

export const changeStatusBook = ({ id, params }: { id: number, params: any }) => {
  return http.patch(`${booksEndpoint}/${id}`, params)
}

export const getClassroomsPerformance = ({ id }: { id: number }) => {
  return http.get(`${booksEndpoint}/${id}/classrooms_performance`)
}

export const getPerformanceComparisonData = ({ id, params }: { id: number, params: any }) => {
  return http.get(`${booksEndpoint}/${id}/classrooms_comparison`, { params })
}

export const getUnitsPerformance = ({ id }: { id: number }) => {
  return http.get(`${booksEndpoint}/${id}/units_performance`)
}

export const getBooksGeneral = (params: any) => {
  return http.get(`${booksEndpoint}/general_vision`, { params })
}

export const getBooksRanking = () => {
  return http.get(`${booksEndpoint}/ranking`)
}

export const getBooksAuthorsRanking = () => {
  return http.get(`${autorsEndpoint}/ranking`)
}

export const getBooksCoauthorsRanking = () => {
  return http.get(`${coautorsEndpoint}/ranking`)
}

export const getBookStudentsPerformance = (schoolUnitId: any) => {
  return http.get(`${booksEndpoint}/general_performance?school_unit_ids=${schoolUnitId}`)
}

export const getBookClassroomsPerformance = (bookId: any) => {
  return http.get(`${booksEndpoint}/${bookId}/classrooms_performance`)
}

export const getTeachersAuthors = (params: any) => {
  return http.get(teachersAuthors, { params })
}
