import React from 'react'
import {
  Avatar,
  Box,
  Typography
} from '@mui/material'
import { stringAvatar } from '../../../utils/functions'
import {
  CollapseItemTitle,
  PerformanceTitle
} from '../../../containers/BooksReportClassroomPerformanceContainer'
import { useTheme } from '@mui/material/styles'

const ClassroomStudentCardReport = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        background: '#FFF',
        borderRadius: '8px',
        padding: '9px 16px',
        width: '100%',
        maxWidth: '530px',
        justifyContent: 'space-between',
        marginRight: '20px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px'
        }}
      >
        <Avatar {...stringAvatar('Erika Silva')} variant='rounded' />
        <PerformanceTitle>Erika Silva</PerformanceTitle>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '4px'
        }}
      >
        <CollapseItemTitle>Desempenho:</CollapseItemTitle>
        <Typography
          sx={{
            color: '#04AEC9',
            fontWeight: 500
          }}>
          000%
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '4px'
        }}
      >
        <CollapseItemTitle>Desempenho:</CollapseItemTitle>
        <Typography
          sx={{
            color: theme.palette.primary.light,
            fontWeight: 500
          }}>
          000%
        </Typography>
      </Box>
    </Box>
  )
}

export default ClassroomStudentCardReport
