import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { JsonFormat } from '../../utils/types'
import { withPayloadType } from '../../utils/functions'
import { IEducation } from '../../models/IEducation'

export type EducationJson = JsonFormat<'education', IEducation>

interface FetchSubjectsSuccessPayload {
  subjects: object[]
}

interface FetchThemesSuccessPayload {
  subjects: object[]
}

interface FailurePayload {
  message: string
}

interface FetchGradesSuccessPayload {
  grades: object[]
}

interface FetchSegmentsSuccessPayload {
  segments: object[]
}

interface ITeachers {
  id: number
  subject: { id: number, name: string }
  grade: { id: number, name: string }
  user: { id: number, name: string, email: string }
}

export const subjectsFetchRequest = createAction('education/SUBJECTS_FETCH_REQUEST')
export const subjectsFetchSuccess = createAction('education/SUBJECTS_FETCH_SUCCESS', withPayloadType<FetchSubjectsSuccessPayload>())
export const subjectsFetchFailure = createAction('education/SUBJECTS_FETCH_FAILURE', withPayloadType<FailurePayload>())

export const themesFetchRequest = createAction('education/THEMES_FETCH_REQUEST')
export const themesFetchSuccess = createAction('education/THEMES_FETCH_SUCCESS', withPayloadType<FetchThemesSuccessPayload>())
export const themesFetchFailure = createAction('education/THEME_FETCH_FAILURE', withPayloadType<FailurePayload>())

export const gradesFetchRequest = createAction('education/GRADES_FETCH_REQUEST')
export const gradesFetchSuccess = createAction('education/GRADES_FETCH_SUCCESS', withPayloadType<FetchGradesSuccessPayload>())
export const gradesFetchFailure = createAction('education/GRADES_FAILURE', withPayloadType<FailurePayload>())

export const segmentsFetchRequest = createAction('education/SEGMENTS_FETCH_REQUEST')
export const segmentsFetchSuccess = createAction('education/SEGMENTS_FETCH_SUCCESS', withPayloadType<FetchSegmentsSuccessPayload>())
export const segmentsFetchFailure = createAction('education/SEGMENTS_FETCH_FAILURE', withPayloadType<FailurePayload>())

export const teachersBySchoolFetchRequest = createAction('education/TEACHERS_BY_SCHOOL_FETCH_REQUEST')
export const teachersBySchoolFetchSuccess = createAction('education/TEACHERS_BY_SCHOOL_FETCH_SUCCESS', withPayloadType<ITeachers[]>())
export const teachersBySchoolFetchFailure = createAction<string>('education/TEACHERS_BY_SCHOOL_FETCH_FAILURE')

interface EducationSegment {
  id: number
  name: string
}

interface GradeItems {
  id: number
  name: string
  education_segment: EducationSegment
}

interface SegmentItems {
  id: number
  name: string
  grades: GradeItems[]
}

interface StateType {
  subjects: any
  themes: object
  grades: {
    items: GradeItems[] | null,
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  }
  segments: {
    items: SegmentItems[] | null,
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  },
  teachers: {
    items: ITeachers[],
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  }
}

const initialState: StateType = {
  subjects: {
    items: null,
    isFetching: false,
    isError: false,
    messageError: null
  },
  themes: {
    items: null,
    isFetching: false,
    isError: false,
    messageError: null
  },
  segments: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  },
  grades: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  },
  teachers: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  }
}

const educationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(subjectsFetchRequest, (state: StateType): StateType => ({
      ...state,
      subjects: {
        ...state.subjects,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(subjectsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subjects: {
        ...state.subjects,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(subjectsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subjects: {
        ...state.subjects,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(themesFetchRequest, (state: StateType): StateType => ({
      ...state,
      themes: {
        ...state.themes,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(themesFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      themes: {
        ...state.themes,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(themesFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      themes: {
        ...state.themes,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(gradesFetchRequest, (state: StateType) => ({
      ...state,
      grades: {
        ...state.grades,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(gradesFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      grades: {
        ...state.grades,
        isFetching: false,
        items: action.payload
      }
    }))
    .addCase(gradesFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      grades: {
        ...state.grades,
        isFetching: false,
        isError: true,
        messageError: action.payload.message
      }
    }))
    .addCase(segmentsFetchRequest, (state: StateType) => ({
      ...state,
      segments: {
        ...state.segments,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(segmentsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      segments: {
        ...state.segments,
        isFetching: false,
        items: action.payload
      }
    }))
    .addCase(segmentsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      segments: {
        ...state.segments,
        isFetching: false,
        isError: true,
        messageError: action.payload.message
      }
    }))
    .addCase(teachersBySchoolFetchRequest, (state: StateType) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(teachersBySchoolFetchSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(teachersBySchoolFetchFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: false,
        isError: false,
        messageError: action.payload
      }
    }))
})

export default educationReducer
