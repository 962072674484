import http from '../../utils/http'

const subjectsEndpoint = '/subjects'
const postThemesEndpoint = '/post_themes'
const segmentsEndpoint = '/segments'
const teachersEndpoint = '/teachers'

export const fetchSubjects = () => {
  return http.get(subjectsEndpoint)
}

export const fetchThemes = () => {
  return http.get(postThemesEndpoint)
}

export const fetchSegments = () => {
  return http.get(`${segmentsEndpoint}?include_grades=true`)
}

export const fetchTeachersBySchool = (params: object) => {
  return http.get(`${teachersEndpoint}/search`, { params })
}
