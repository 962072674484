import React from 'react'
import {Avatar, Box, Grid, Paper, Skeleton, Typography, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import { ActivityParticipants, Participants } from '../../models/IActivities'
import { ButtonLightStyled } from '../button/Button'
import PerformanceTable, { Columns } from '../table/PerformanceTable'
import { stringAvatar } from '../../utils/functions'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

const BookStudentsPerformanceView: any = ({studentsPerformance} : any) => {
  const navigate = useNavigate()

  const gotToStudetsPerformancePage = () => {
    navigate('/books/reports/students-performance')
  }

  const getColumns = () => {
    const columns: Columns[] = [
      {
        key: 'avatar',
        name: 'Nome',
        width: '45px',
        render: (row : any) => {
          return <Avatar {...stringAvatar(row.user.name)} variant='rounded' />
        }
      },
      {
        key: 'students',
        name: '',
        render: (row : any) => {
          return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.user.name}</Typography>
          </Box>
        }
      },
      {
        key: 'school',
        name: 'Escola(s)',
        render: (row : any) => {
          return <Box display='flex' flexDirection='column' >
            <p style={{ margin: '0' }}>{row.school_classroom.school_unit.name}</p>
          </Box>
        }
      },
      {
        key: 'scores',
        name: 'Livros',
        width: '150px',
        render: (row : any) => {
          return <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%' }}>
          <Typography>{row.access}</Typography>
        </Box>
        }
      },
      {
        key: 'performance',
        name: 'Desempenho',
        width: '150px',
        render: (row : any) => {
          return <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%', justifyContent: 'flex-end' }}>
           <LinearProgress
              sx={{ maxWidth: '80px', width: '100%', backgroundColor: '#e1e1e1' }}
              color='secondary'
              value={row.performance}
              variant='determinate'
            />
            <Typography>{row.performance.toFixed(0)}%</Typography>
        </Box>
        }
      }
    ]

    return columns
  }

  const students = studentsPerformance && studentsPerformance?.items

  return (
    <Paper sx={paperStyled}>
      {
        studentsPerformance?.isFetching ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton animation='wave' height={50} width={100} />
              <Skeleton animation='wave' height={50} width={100} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '32px'
              }}
            >
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />

              </Box>
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
              </Box>

            </Box>
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' mb={2}>
              <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
                <PerformanceSVG style={{ marginRight: 16 }} />
                Alunos com melhores desempenhos
              </Typography>
              <ButtonLightStyled
                onClick={gotToStudetsPerformancePage}
                startIcon={<ViewSVG />}
                variant='outlined'
              >
                Visualizar lista completa
              </ButtonLightStyled>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ width: 520 }}>
                <PerformanceTable
                  columns={getColumns()}
                  data={students && students?.slice(0, 5) || []}
                  withoutHead
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Paper>
  )
}

export default BookStudentsPerformanceView
