import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import BookIcon from '../assets/components/Books/book-icon.svg'
import PeopleIcon from '../assets/components/Books/people-light-icon.svg'
import PersonIcon from '../assets/components/Books/person-icon.svg'
import ListIcon from '../assets/components/Books/list-icon.svg'
import { ReactComponent as DownloadIcon } from '../assets/components/activity/download-icon.svg'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'

// Components
import SearchInput from '../components/input/SearchInput'
import {
  Box,
  Collapse,
  Paper,
  Typography
} from '@mui/material'
import Button from '../components/button/Button'
import { PageTitle } from '../components/title/Title'

// Styles
import { styled, useTheme } from '@mui/material/styles'
import SingleClassroomCardReport from '../components/Books/card/SingleClassroomCardReport'

const ExportButton = styled('button')(() => ({
  padding: '15px 40px',
  backgroundColor: '#BC9BFA',
  color: '#fff',
  borderRadius: '8px',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  'svg': {
    marginRight: '8px'
  },
  ':hover': {
    backgroundColor: '#04AEC9'
  },
  ':active': {
    backgroundColor: '#60DFC8'
  }
}))
export const LinearProgressBox = styled(Box)(({ theme }) => ({
  width: '72px',
  display: 'grid',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
export const CollapseItemTitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  color: '#9F9F9F',
  lineHeight: '120%'
}))
export const CollapseItemContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}))
export const PerformanceTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '120%'
}))

const BooksReportClassroomPerformanceContainer = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [searchName, setSearchName] = useState<string>('')
  const [toggleOpen, setToggleOpen] = useState(false)
  const [toggleOpenClassroom, setToggleOpenClassroom] = useState(false)
  const resolutionSectionRef = useRef<HTMLDivElement | null>(null)

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }
  const handleSeeSchool = () => {
    setToggleOpen(!toggleOpen)
  }
  const handleSeeClassroom = () => {
    setToggleOpenClassroom(!toggleOpenClassroom)
  }

  return (
    <Box
      sx={{
        padding: '55px 12px 117px'
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', marginBottom: '32px' }}>
        <img src={BookIcon} />
        <PageTitle>
          Matemática Básica Volume 1 /  Alunos que mais acertaram
        </PageTitle>
      </Box>
      <Box
        mb='44px'
        display='flex'
        gap='70px'
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            width: '100%'
          }}
        >
          <Typography fontWeight={500}>Escola</Typography>
          <SearchInput
            name='school-name'
            value={searchName}
            onChange={handleSearchByName}
            placeholder='Pesquise pelo nome da escola'
            width='100%'
            border='1px solid #d9d9d9'
            borderRadius='8px'
            icon={PersonIcon}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            width: '100%'
          }}
        >
          <Typography fontWeight={500}>Turma</Typography>
          <SearchInput
            name='classroom-name'
            value={searchName}
            onChange={handleSearchByName}
            placeholder='Pesquise pelo nome da turma'
            width='100%'
            border='1px solid #d9d9d9'
            borderRadius='8px'
            icon={PeopleIcon}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            width: '100%'
          }}
        >
          <Typography fontWeight={500}>Aluno</Typography>
          <SearchInput
            name='student-name'
            value={searchName}
            onChange={handleSearchByName}
            placeholder='Pesquise pelo nome do aluno'
            width='100%'
            border='1px solid #d9d9d9'
            borderRadius='8px'
            icon={PersonIcon}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '16px'
        }}
      >
        <img src={ListIcon} alt='ícone de livro' />
        <PageTitle>
          Visão Geral
        </PageTitle>
      </Box>
      <Paper
        sx={{
          borderRadius: '16px',
          padding: '32px 20px 34px 24px'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            gap: '16px'
          }}
          onClick={handleSeeSchool}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
            }}

          >
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                '& > p': {
                  fontSize: '14px',
                  fontWeight: 600
                }
              }}
            >
              <Typography>Escola Serios </Typography>
              <Typography
                sx={{
                  color: `${theme.palette.primary.light} !important`
                }}
              >
                (3)
              </Typography>
            </Box>
            {!toggleOpen ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowUpRoundedIcon />}
          </Box>
        </Box>
        <Collapse in={toggleOpen} timeout={500} >
          <SingleClassroomCardReport
            resolutionSectionRef={resolutionSectionRef}
            handleSeeClassroom={handleSeeClassroom}
            toggleOpenClassroom={toggleOpenClassroom}
          />
        </Collapse>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          justifyContent: 'flex-end',
          marginTop: '32px',
        }}
      >
        <ExportButton onClick={() => null}>
          <DownloadIcon />
          Exportar Relatório
        </ExportButton>
        <Button
          type='button'
          variant='contained'
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  )
}

export default BooksReportClassroomPerformanceContainer
