// React
import React, { useCallback, useEffect } from 'react'

// Components
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import Button from '../components/button/Button'
import PerformanceChartView from '../components/view/PerformanceChartView'
import PerformanceStudentsView from '../components/view/PerformanceStudentsView'
import PerformanceClassroomView from '../components/view/PerformanceClassroomView'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  classroomsPerformanceRequest,
  clearClassroomsPerformance,
  clearPerformanceComparisonData,
  clearUnitsPerformance,
  fetchPerformanceComparisonDataRequest,
  fetchUnitsPerformanceRequest
} from '../store/books/actions'

// Utils
import { scrollToTop } from '../utils/functions'
import { styled, useTheme } from '@mui/material/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
import AccessImage from '../assets/components/Books/access-image.svg'
import AverageImage from '../assets/components/Books/average-image.svg'
import ClassroomImage from '../assets/components/Books/classroom-image.svg'
import SchoolImage from '../assets/components/Books/schools-image.svg'

const GridContainer = styled(Grid)(({ theme }) => ({
  marginInline: 'auto',
  maxWidth: '1096px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '502px'
  }
}))

const BoxCard = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  height: '200px',
  maxWidth: '247px',
  width: '100%',

  '.container': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    padding: '10px',

    '& .information': {
      display: 'flex',
      gap: '2px',

      '& span': {
        fontSize: '14px',
        letterSpacing: '0.14px',
        lineHeight: '120%',

        '&:first-of-type': {
          color: theme.palette.primary.light,
          fontWeight: 800
        },

        '&:last-of-type': {
          color: theme.palette.text.primary,
          fontWeight: 500
        }
      }
    }
  }
}))

const BooksReportContainer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const {
    classrooms_performance: { data, isFetching, isError },
    performance_comparison: performanceComparison,
    units_performance: unitsPerformance
  } = useAppSelector(state => state.books)

  const handleBackPage = () => {
    navigate('/books')
  }

  const handleGetChartData = useCallback((params?: object) => {
    dispatch(fetchPerformanceComparisonDataRequest({
      bookId: Number(id),
      filters: params
    }))
  }, [])

  useEffect(() => {
    scrollToTop()
    if (id) {
      dispatch(classroomsPerformanceRequest({ id: Number(id) }))
      handleGetChartData()
      dispatch(fetchUnitsPerformanceRequest({ bookId: Number(id) }))
    }

    return () => {
      dispatch(clearClassroomsPerformance())
      dispatch(clearPerformanceComparisonData())
      dispatch(clearUnitsPerformance())
    }
  }, [])

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>

      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
        <img src={BookIcon} alt='ícone de livro' />
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600
          }}
        >
          {state?.bookTitle}
        </Typography>
      </Box>

      <Box
        sx={{
          border: `1px dashed ${theme.palette.text.secondary}`,
          borderRadius: '16px',
          padding: '24px 32px 36px 24px',
          marginTop: '32px'
        }}
      >
        {isFetching ? (
          <GridContainer
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            {Array(4).fill(0).map((_, index) => {
              return (
                <Grid item xs={6} md={3} key={index}>
                  <Skeleton
                    animation='wave'
                    variant='rounded'
                    sx={{
                      height: '200px',
                      maxWidth: '247px',
                      width: '100%'
                    }}
                  />
                </Grid>
              )
            })}
          </GridContainer>
        ) : (
          <GridContainer
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={AccessImage} alt='ícone de nº de acessos' />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.access ?? 0}
                    </Box>
                    <Box component='span'>
                      {data?.access && data?.access <= 1 && 'Acesso'}
                      {data?.access && data?.access > 1 && 'Acessos'}
                      {isError && 'Acessos'}
                      {!data?.access && !isError && 'Acessos'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={AverageImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.total_average ? data?.total_average?.toFixed(1) : '0'}%
                    </Box>
                    <Box component='span'>
                      Média de desempenho
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={ClassroomImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.school_classrooms ? data?.school_classrooms.length : '000'}
                    </Box>
                    <Box component='span'>
                      {data?.school_classrooms && data.school_classrooms.length > 1 ? 'Turmas' : 'Turma'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={SchoolImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.units?.length ?? '000'}
                    </Box>
                    <Box component='span'>
                      {data?.units && data?.units?.length > 1 ? 'Escolas' : 'Escola'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>
          </GridContainer>
        )}
      </Box>

      <PerformanceChartView
        isFetching={performanceComparison.isFetching}
        isError={performanceComparison.isError}
        data={performanceComparison?.data}
        handleGetData={handleGetChartData}
      />

      <Box
        sx={{
          background: '#ffffff',
          borderRadius: '16px',
          marginTop: '16px',
          padding: '48px 24px'
        }}
      >
        <PerformanceStudentsView bookId={Number(id)} />
      </Box>

        <PerformanceClassroomView
          bookId={Number(id)}
          isFetching={unitsPerformance.isFetching}
          isError={unitsPerformance.isError}
          data={unitsPerformance.data}
        />
    </Box>
  )
}

export default BooksReportContainer
