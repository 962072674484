// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material'
import LineChartComponent from '../charts/LineChart'
import SimpleSelect from '../select/SimpleSelect'
import ErrorMessage from '../message/ErrorMessage'

// Utils
import { styled } from '@mui/material/styles'
import { IPerformanceComparisonData } from '../../models/IBooks'

// Icons
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import BagIcon from '../../assets/components/Books/bag-icon.svg'
import BookIcon from '../../assets/components/Books/book-icon.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const Container = styled(Box)(() => ({
  background: '#ffffff',
  borderRadius: '16px',
  marginTop: '32px',
  padding: '48px 24px'
}))

const FiltersContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '120px',
  marginBottom: '18px'
}))

const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}))

const ChartContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',

  '& .btn': {
    background: 'rgba(217, 217, 217, 0.4)',
    height: 'fit-content',
    transition: 'background 0.3s ease-in-out',
    width: 'fit-content',

    '& .icon': {
      color: 'rgb(102, 102, 102)',
      fontSize: '16px',
      textAlign: 'center'
    },

    '&.Mui-disabled': {
      '& .icon': {
        color: '#d9d9d9'
      }
    },

    '&:hover': {
      background: 'rgba(217, 217, 217, 0.8)'
    }
  },

  '& .btn_previous': {
    '& .icon': {
      marginLeft: '4px',
    }
  },

  '& .btn_next': {
    marginLeft: '24px'
  }
}))

interface IOption {
  id: number
  name: string
}

interface ISchoolOption extends IOption {
  classrooms?: { id: number, name: string }[]
  grades?: { id: number, name: string }[]
}

interface IPerformanceChartViewProps {
  isFetching: boolean
  isError: boolean
  data: IPerformanceComparisonData[]
  handleGetData: (params?: object) => void
}

const PerformanceChartView: React.FC<IPerformanceChartViewProps> = ({
  isFetching,
  isError,
  data,
  handleGetData
}) => {
  const [school, setSchool] = useState<ISchoolOption | null>(null)
  const [schoolOptions, setSchoolOptions] = useState<ISchoolOption[]>([])
  const [grade, setGrade] = useState<IOption | null>(null)
  const [gradeOptions, setGradeOptions] = useState<IOption[]>([])
  const [chartData, setChartData] = useState<any>([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const paginateData = () => {
    const newData = data?.[0]?.book_chapters?.map((_, index) => {
      const result: any = { name: `Cap. ${index + 1}` }
      data.forEach(school => {
        const performance = school?.book_chapters?.[index]?.performance
        const numberOfModules = school?.book_chapters?.[index]?.modules?.length
        result[school.school_classroom.name] = performance
        result[`${school.school_classroom.name} - modules`] = numberOfModules
        result[`${school.school_classroom.name} - id`] = school.school_classroom.id
      })

      return result
    })

    if (newData && newData.length > 10) {
      setTotalPages(Math.ceil(newData.length / 10))
      let startIndex
      if (currentPage > 1) {
        startIndex = (currentPage - 1) * 10 - 1
      } else {
        startIndex = (currentPage - 1) * 10
      }
      const endIndex = startIndex + 10
      const newChartData = newData.slice(startIndex, endIndex)
      setChartData(newChartData)
    } else {
      setChartData(newData)
    }
  }

  const loadSchoolOptions = () => {
    const schoolOptionsFiltered = data.reduce((acc: any, item) => {
      const schoolIndex = acc.findIndex((school: any) => school.id === item.school_classroom.school_unit.id)

      if (schoolIndex !== -1) {
        acc?.[schoolIndex]?.classrooms?.push({
          id: item.school_classroom.id,
          name: item.school_classroom.name
        })
        acc?.[schoolIndex]?.grades?.push({
          id: item.school_classroom.grade.id,
          name: item.school_classroom.grade.name
        })
      } else {
        acc.push({
          id: item.school_classroom.school_unit.id,
          name: item.school_classroom.school_unit.name,
          classrooms: [{
            id: item.school_classroom.id,
            name: item.school_classroom.name
          }],
          grades: [{
            id: item.school_classroom.grade.id,
            name: item.school_classroom.grade.name
          }]
        })
      }
      return acc
    }, [])

    schoolOptionsFiltered.forEach((item: any) => {
      if (!schoolOptions.some((school) => school.id === item.id)) {
        setSchoolOptions(prevState => [...prevState, item])
      }
    })
  }

  const loadGradeOptions = () => {
    const gradeOptionsFiltered = data.reduce((acc: IOption[], item) => {
      const grade = item.school_classroom.grade
      if (!acc.some((i: any) => i?.id === grade.id)) {
        acc.push({ id: grade.id, name: grade.name })
      }
      return acc
    }, [])

    gradeOptionsFiltered.forEach((item) => {
      if (!gradeOptions.some((grade) => grade.id === item.id)) {
        setGradeOptions(prevState => [...prevState, item])
      }
    })
  }

  useEffect(() => {
    paginateData()
  }, [data, currentPage])

  useEffect(() => {
    loadSchoolOptions()
    loadGradeOptions()
  }, [data])

  const handleSchoolFilter = (event: ISchoolOption) => {
    setSchool(event)
    if (!event) {
      loadGradeOptions()
      handleGetData({
        school_unit_ids: null
      })
    } else {
      handleGetData({
        school_unit_ids: String(event?.id)
      })
      setGradeOptions(event.grades as IOption[])
    }
  }

  const handleGradeFilter = (event: any) => {
    setGrade(event)
    if (!event) {
      handleGetData({ education_grade_ids: null })
      loadSchoolOptions()
    } else {
      const school = schoolOptions.filter((item) => item.grades?.some(grade => grade.id === event.id))
      setSchoolOptions(school)
      handleGetData({ education_grade_ids: String(event?.id) })
    }
  }

  const handleNextPage = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1)
    } else {
      setCurrentPage(prevState => prevState + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(prevState => prevState - 1)
    }
  }

  return (
    <Container>
      <FiltersContainer>
        <BoxTitle>
          <img src={BookIcon} />
          <Typography className='title'>
            Comparativo de desempenho
          </Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SimpleSelect
            className='school'
            options={schoolOptions}
            value={school}
            onChange={handleSchoolFilter}
            placeholder='Escola...'
            isClearable
          />
        </BoxFilter>

        <BoxFilter>
          <img src={BagIcon} />
          <SimpleSelect
            className='classroom'
            options={gradeOptions}
            value={grade}
            onChange={handleGradeFilter}
            placeholder='Série...'
            isClearable
          />
        </BoxFilter>
      </FiltersContainer>

      {isFetching && (
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '300px', width: '100%' }}
        />
      )}

      {!isFetching && !isError && (
        <Box>
          <ChartContainer>
            <IconButton
              type='button'
              onClick={handlePreviousPage}
              className='btn btn_previous'
              disabled={currentPage === 1}
            >
              <ArrowBackIosIcon className='icon' />
            </IconButton>

            <LineChartComponent
              label={data}
              chartData={chartData}
            />

            <IconButton
              type='button'
              onClick={handleNextPage}
              className='btn btn_next'
              disabled={currentPage === totalPages}
            >
              <ArrowForwardIosIcon className='icon' />
            </IconButton>
          </ChartContainer>
        </Box>
      )}

      {isError && (
        <ErrorMessage />
      )}
    </Container>
  )
}

export default PerformanceChartView
